<!-- =========================================================================================
  File Name: Profile.vue
  Description: Profile Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<!-- =========================================================================================
    File Name: CardActions.vue
    Description: Card with actions
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div class="vx-row">

        <!-- Overview -->
        <div class="vx-col w-full mb-base">
            <vx-card title="Card Actions" action-buttons @refresh="closeCardAnimationDemo">
            <table style="width:100%" class="border-collapse">
                <tr class="text-center table-primary">
                    <th class="p-2 border border-solid d-theme-border-grey-light"> Name </th>
                    <th class="p-2 border border-solid d-theme-border-grey-light text-center">Icon</th>
                    <th class="p-2 border border-solid d-theme-border-grey-light">Email</th>
                </tr>
                <tr>
                    <td class="p-2 border border-solid d-theme-border-grey-light">{{ activeUserInfo.name }}</td>
                    <td class="border border-solid d-theme-border-grey-light text-center"><feather-icon icon="at-sign" svgClasses="h-5 w-5" /></td>
                    <td class="p-2 border border-solid d-theme-border-grey-light">Collapse card content using collapse action.</td>
                </tr>
                <tr>
                    <td class="p-2 border border-solid d-theme-border-grey-light">Refresh Content</td>
                    <td class="border border-solid d-theme-border-grey-light text-center"><feather-icon icon="command" svgClasses="h-4 w-4" /></td>
                    <td class="p-2 border border-solid d-theme-border-grey-light">Refresh your card content using refresh action.</td>
                </tr>

            </table>
            </vx-card>
       
    </div>
    </div>
</template>

<script>
export default {
  methods: {
    closeCardAnimationDemo (card) {
      card.removeRefreshAnimation(3000)
    }
  },
  computed:{
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  }
}
</script>


<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss"


</style>
